export default {
  // 获取菜单导航栏数据
  getMenuDataApi: {
    url: '/api/home/index/menu',
    method: 'get',
  },
  // 获取底部导航栏数据
  getFootDataApi: {
    url: '/api/home/index/foot',
    method: 'get',
  },
  //获取首页数据
  getHomeDataApi: {
    url: "/api/home/index/index",
    method: "get",
  },
  //关于我们
  getAboutDataApi: {
    url: "/api/home/index/about_us",
    method: "get",
  },
  //集团业务
  getCompanyDataApi: {
    url: "/api/home/index/company",
    method: "get",
  },
  //新闻中心
  getNewsDataApi: {
    url: "/api/home/index/news",
    method: "get",
  },
  //获取新闻列表
  getNewsListApi: {
    url: "/api/home/index/news_list",
    method: "get",
  },
  //联系我们
  getContactDataApi: {
    url: "/api/home/index/contact_us",
    method: "get",
  },
  //提交需求
  getDemandDataApi: {
    url: "/api/home/index/post_user_demand",
    method: "post",
  },
};



//   const getHomeDataApi = () => {
//     // 函数实现
//     url: "/api/home/index/index",
//     method: "get",
//   };

//   export default getHomeDataApi;