<template>
  <div style="width: 100vw;height: 100vh; background-color: #fff">
    <div class="header">
      <div class="tab_box" @click="showPop">
        <img src="../../image/tab_icon.png" alt="" />
        <van-popup v-model="showPopup" position="left" :style="{width:'50%', height: '100%' }" :close-on-click-overlay="false"  >
          <div
            class="popup_item"
            v-for="(item, index) in popList"
            :key="item.id"
            :style="{ borderTop: index == 0 ? 'none' : '1px solid #1A202C' ,color:item.url==$route.path?'#A0A1A5':'#000'}"
            @click.stop="selectItem(item.id, item.url,index)"
          >
            {{item.name}}
          </div>
        </van-popup>
      </div>
      <div class="logo">
        <img :src="footListData.company_set_company_logo" alt="" />
      </div>
    </div>
    <div class="banner">
      <img :src="company_advertisement_img" alt="" />
    </div>
    <div
      class="service_box"
      v-for="(item, index) in company_data"
      :key="item.id"
      :style="{ backgroundColor: index % 2 == 0 ? '#F8F8F8' : '#F6FAFD' }"
    >
      <div class="box_img">
        <img :src="item.img" alt="" />
      </div>
      <div class="box_name">{{item.title}}</div>
      <div class="box_content">
        {{item.describe}}
      </div>
    </div>
    <div class="foot">
        {{footListData.company_set_filings}}
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  data() {
    return {
      showPopup: false,
      list: [1, 2, 3, 4, 5],
      popList: [],//菜单导航栏
      company_advertisement_img: "", //轮播图
      company_data: [],
      footListData:{},
    };
  },
  created() {
    request("getCompanyDataApi").then((res) => {
      console.log(res);
      this.company_advertisement_img=res.data.company_advertisement_img;
      this.company_data=res.data.company_data;
    });
    request("getFootDataApi").then((red) => {
      console.log(red);
      this.footListData=red.data;
    });
    request("getMenuDataApi").then((red) => {
      // console.log(red);
      this.popList = red.data.menu_data;
    });
  },
  methods: {
    selectItem(id, url,index) {
      // if (index == 0) return;
      // 点击选项时触发的方法
      // this.selectedItem = id;
      if (this.$route.path== url){
        return this.showPopup=false;
      };
      this.$router.push({
        path: url,
        query: { id },
      });
      // if (id == this.parentId ) {
      //   console.log("jinru");
      //   return;
      // }
      // if(!this.parentId){
      //   this.$router.push({
      //   path: this.popList[index].url,
      //   query: { id },
      // });
      // this.parentId=id;
      // return;
      // };
      // this.$router.push({
      //   path: url,
      //   query: { id },
      // });
      // this.showPopup=false;
    },
    showPop() {
      console.log(123);
      this.showPopup = !this.showPopup;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>