<template>
  <div style="width: 100vw; background-color: #fff">
    <div class="header">
      <div class="tab_box" @click="showPop">
        <img src="../../image/tab_icon.png" alt="" />
        <van-popup v-model="showPopup" position="left" :style="{width:'50%', height: '100%' }" :close-on-click-overlay="false"  >
          <div
            class="popup_item"
            v-for="(item, index) in popList"
            :key="item.id"
            :style="{ borderTop: index == 0 ? 'none' : '1px solid #1A202C' ,color:item.url==$route.path?'#A0A1A5':'#000'}"
            @click.stop="selectItem(item.id, item.url,index)"
          >
            {{item.name}}
          </div>
        </van-popup>
      </div>
      <div class="logo">
        <img :src="footListData.company_set_company_logo" alt="" />
      </div>
    </div>
    <div class="banner">
      <img :src="new_advertisement_img" alt="" />
    </div>
    <div class="news_box">
      <div class="news_title">
        <div class="company-news" v-for="(item,index) in news_classify_data" :key="item.id" @click="newsTab(index)" >
          <div class="company-news-img"><img :src="index == newsTabIndex?require('@/image/choose_news.png'):require('@/image/choose_news_w.png')" alt="" /></div>
          <!-- <div class="company-news-img"><img :src="index == newsTabIndex?'../../image/choose_news.png':'../../image/choose_news_w.png'" alt="" /></div> -->
          <img v-if="index == newsTabIndex" src="../../image/media_icon_w.png" alt="" class="company-news-icon">
          <img v-if="index !== newsTabIndex" src="../../image/media_icon_y.png" alt="" class="company-news-icon">
          <p class="company-news—title" :style="{ color: index == newsTabIndex ? '#FFFFFF' : ' #1A202C' }">{{item.name}}</p>
        </div>
        <!-- <div class="hot-news">
          <img src="" alt="" />
          <p class="company-news—title">媒体聚焦</p>
        </div> -->
      </div>
      <!-- 避免找不到news_data报错所以加v-if来判断  数据还没有返回之前没数据报错-->
      <div v-if="news_classify_data[newsTabIndex]">
        <div class="news_wrap" v-for="item in news_classify_data[newsTabIndex].news_data.data" :key="item.id">
        <div class="wrap_img">
          <img :src="item.img" alt="" />
        </div>
        <div class="wrap_item">
          <div class="item_title">{{item.title}}</div>
          <div class="item_content">
            {{ item.describe }}
          </div>
          <div class="item_time">{{item.created_at}}</div>
        </div>
      </div>
      </div>
    </div>
    <div class="foot">{{ footListData.company_set_filings }}</div>
  </div>
</template>
  
  <script>
  import request from "../../api/request";
export default {
  data() {
    return {
      imgS:'../../image/choose_news.png',
      imgY:'/image/choose_news.png',
      showPopup: false,
      list: [1, 2, 3, 4, 5],
      popList: [],//菜单导航栏
      footListData:{},
      news_classify_data:[],//新闻分类模块
      news_wrap_data:[],//新闻模块具体数据
      new_advertisement_img:'',//介绍图
      newsTabIndex: 0, //新闻切换索引,一开始没点击,不能给null,默认给0,第一项
    };
  },
  created() {
    request("getNewsDataApi").then((res) => {
      console.log(res);
      this.new_advertisement_img=res.data.new_advertisement_img
      this.news_classify_data=res.data.news_data;
      this.news_classify_data.forEach(red=>{
        this.news_wrap_data=red.news_data.data;
      })
      console.log( this.news_classify_data);
      
    });
    request("getFootDataApi").then((red) => {
      // console.log(red);
      this.footListData = red.data;
    });
    request("getMenuDataApi").then((red) => {
      // console.log(red);
      this.popList = red.data.menu_data;
    });
  },
  methods: {
    selectItem(id, url,index) {
      // if (index == 0) return;
      // 点击选项时触发的方法
      // this.selectedItem = id;
      if (this.$route.path== url){
        return this.showPopup=false;
      };
      this.$router.push({
        path: url,
        query: { id },
      });
      // if (id == this.parentId ) {
      //   console.log("jinru");
      //   return;
      // }
      // if(!this.parentId){
      //   this.$router.push({
      //   path: this.popList[index].url,
      //   query: { id },
      // });
      // this.parentId=id;
      // return;
      // };
      // this.$router.push({
      //   path: url,
      //   query: { id },
      // });
      this.showPopup=false;
    },
    //新闻切换
    newsTab(index) {
      this.newsTabIndex = index;
    },
    showPop() {
      // console.log(123);
      this.showPopup = !this.showPopup;
    },
    
  },
};
</script>
  
  <style lang="scss" scoped>
@import "./index.scss";
</style>