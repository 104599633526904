import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import utils from "./utils/index.js";

import yjs from "./utils/index.js";

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

// vue2
// Vue.prototype.$utils = utils;

// import VueAMap from 'vue-amap'

// Vue.use(VueAMap)
// VueAMap.initAMapApiLoader({
//   key: 'eff0668dcd54182eae1ba79c88918815',
//   plugin: ['AMap.Geolocation']
// })
// console.log(Amap);
Vue.prototype.$axios = axios;

Vue.use(ElementUI);

Vue.prototype.$yjs = yjs;

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
