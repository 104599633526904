import axios from "axios";
import apiList from "./index";
//axios实例化
const instance = axios.create({
  baseURL: "http://webjoint.pl018.com/",
  timeout: 60 * 1000,
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data;
  },
  (error) => {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error.message);
  }
);

//导出请求方法
export default (apiName, params = {}) => {
  if (!apiList[apiName]) {
    return console.log("找不到对应的api");
  }
  const apiInfo = apiList[apiName];
  if (apiInfo.method == "get") {
    return instance.get(apiInfo.url, { params });
  }
  if (apiInfo.method == "post") {
    return instance.post(apiInfo.url, params);
  }
};
