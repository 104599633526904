<template>
  <div style="width: 100vw; background-color: #fff">
    <div class="header">
      <!-- <div class="tab_box" @click="showPop">
        <img src="" alt="" />
        <div class="popup" v-if="showPopup">
          <div
            class="popup_item"
            v-for="(item, index) in popList"
            :key="index"
            :style="{ borderTop: index == 0 ? 'none' : '1px solid #1A202C' }"
            @click.stop="selectItem(item.id, item.url,index)"
          >
            {{item.name}}
          </div>
        </div>
      </div> -->
      <div class="tab_box" @click="showPop">
        <img src="../../image/tab_icon.png" alt="" />
        <van-popup v-model="showPopup" position="left" :style="{width:'50%', height: '100%' }" :close-on-click-overlay="false"  >
          <div
            class="popup_item"
            v-for="(item, index) in popList"
            :key="item.id"
            :style="{ borderTop: index == 0 ? 'none' : '1px solid #1A202C' ,color:item.url==$route.path?'#A0A1A5':'#000'}"
            @click.stop="selectItem(item.id, item.url,index)"
          >
            {{item.name}}
          </div>
        </van-popup>
      </div>
      <div class="logo">
        <img :src="logo" alt="" />
      </div>
    </div>
    <div class="banner">
      <div class="swiper-container swiper1">
        <!-- 需要的额外容器 -->
        <div class="swiper-wrapper">
          <!-- 幻灯片内容 -->
          <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
              <img :src="item.img" alt="" />
          </div>
          <!-- <div class="swiper-slide">
            <img src="../../image/1111.jpg" alt="" />
          </div>
          <div class="swiper-slide">
            <img src="../../image/1111.jpg" alt="" />
          </div> -->
          <!-- 如果需要更多幻灯片，继续添加 -->
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>

        <!-- 如果需要导航按钮 -->
        <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->

        <!-- 如果需要滚动条 -->
        <div class="swiper-scrollbar"></div>
      </div>
      <div class="page-indicator">
        <!-- 显示当前页码 -->
        {{ currpage }} / {{ bannerList.length }}
      </div>
    </div>
    <!-- 集团业务 -->
    <div class="business">
      <div class="business_name">
        集团业务
      </div>
      <!-- <div class="business_img">
        <img src="" alt="">
      </div> -->
      <div class="business_box">
        <el-carousel
          :loop="true"
          :trigger="'never'"   
          :interval="4000"
          type="card"
          height="250px"
          indicator-position="none"
          style="margin-bottom: 20px"
          @change="handleCarouselChange"
        >
          <el-carousel-item v-for="item in businessList" :key="item.id">
            <div class="boxss">
              <div class="item_img">
                <img :src="item.img" alt=""  />
              </div>
              <div class="item_box">{{item.name}}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div
            class="business_content"
            v-for="(item, index) in businessList"
            :key="item.id"
          >
            <div class="company_name" v-if="index == businessPage">
              {{ item.name }}
            </div>
            <div class="company_content" v-if="index == businessPage">
              {{ item.describe }}
            </div>
          </div>
      </div>
    </div>
    <!-- 新闻动态 -->
    <div class="news">
        <div class="news_name">
          <div class="news_name_l">
            <div class="left_name">{{ home_new_name }}</div>
            <!-- <div class="left_more">更多动态>></div> -->
          </div>
          <div class="news_name_r">
            <div
              class="company_news"
              v-for="(item, index) in newsList"
              :key="item.id"
              @click="newsTab(index)"
              :style="{ color: index == newsTabIndex ? '#BC8F22' : ' #1A202C' }"
            >
              {{ item.name }}
            </div>
            <!-- <div class="media_news">媒体新闻</div> -->
          </div>
        </div>
        <!-- 必须当newsList有数据的时候才能获取到里面的news_data ,刚开始页面渲染时没数据,获取不到报错 v-if="newsList.length > 0"-->
        <div class="news_box" v-if="newsList.length > 0">
          <div class="news_time">
            最新动态:{{ newsList[newsTabIndex].news_data[0].created_at }}
          </div>
          <div class="news_title">
            {{ newsList[newsTabIndex].news_data[0].title }}
          </div>
          <div class="more_news">查看详情</div>
        </div>
        <div class="news_wrap" v-if="newsList.length > 0">
          <div
            class="news_wrap_item"
            v-for="(item, index) in newsList[newsTabIndex].news_data"
            :key="item.id"  
            :style="{display:index==0?'none':'block'}"
          >
            <!-- 为了v-for和v-if不同时存在，多加一层盒子包裹循环项 -->
            <div >
              <div class="item_time">
                <div class="item_time_day">{{ item.createdAt_day }}</div>
                <div class="item_time_yearMonth">{{ item.createdAt }}</div>
              </div>
              <div class="item_title">
                {{ item.title }}
              </div>
              <div class="item_content">
                <!-- {{item.news_data[index].describe}} -->
                {{ newsList[newsTabIndex].news_data[index].describe }}
                <!-- {{ item.describe }} -->
              </div>
              <div class="item_detail">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    <!-- 社会责任 -->
    <div class="society" :style="{backgroundImage:'url(' + home_social_responsibility_background +')'}">
        <div class="society_top">
          <div class="society_name">{{home_social_responsibility_name}}</div>
          <div class="society_title">
            {{home_social_responsibility_describe}}
          </div>
          <!-- <div class="society_detail">查看详情</div> -->
        </div>
        <div class="society_box" >
          <div class="society_box_l" v-for="item in social_responsibility_data" :key="item.id">
            <div class="item_left">
              <div class="item_left_img"><img :src="item.icon" alt="" /></div>
              <div class="item_left_name">{{item.name}}</div>
            </div>
            <div class="item_right">
              <div class="item_right_title">{{item.title}}</div>
              <div class="item_right_content">
                {{ item.content_text }}
              </div>
            </div>
          </div>
          <!-- <div class="society_box_r">
            <div class="item_left">
              <div class="item_left_img"><img src="" alt="" /></div>
              <div class="item_left_name">合作伙伴</div>
            </div>
            <div class="item_right">
              <div class="item_right_title"></div>
              <div class="item_right_content"></div>
            </div>
          </div> -->
        </div>
      </div>
    <!-- 宣传视频 -->
    <div
        class="video_box"
      >
        <!-- <video :src="home_video_video" autoplay type="video/mp4"></video> -->
        <div class="play_btn" @click="playVideo" v-if="!isPlaying"><img src="../../image/play_btn.png" alt=""></div>
        <!-- 视频主图 -->
        <img
          v-if="!isPlaying"
          :src="home_video_img"
          alt=""
        />
        <!-- 视频播放器 -->
        <video v-if="isPlaying" ref="videoPlayer" controls @ended="stopVideo">
          <source :src="home_video_video" type="video/mp4" />
        </video>
      </div>
      <!-- 人才加入宣传 -->
      <div class="person_img">
        <img :src="home_talent_development_img" alt="" />
      </div>
    <div class="foot">
      ©Copyright:2023-2099 
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import request from "@/api/request";
export default {
  data() {
    return {
      // newsList: [1, 2, 3],
      // mySwiper: null,
      // currpage: 1,
      // totalSlides: 3, // 根据轮播图的总数设置
      // show: false,
      isPlaying: false,
      showPopup: false,
      list: [1, 2, 3, 4, 5],
      popList: [],//菜单导航栏
      businessPage:0,//集团业务当前轮播索引
      businessList: [
        //集团业务title,文本
        { name: "123", id: "111" },
        { name: "456", id: "222" },
        { name: "789", id: "333" },
      ],
      home_company_name: "", //集团业务模块
      home_new_name: "", //新闻动态模块
      home_social_responsibility_name: "", //社会责任模块
      home_social_responsibility_background: "", //社会责任背景图
      home_social_responsibility_describe: "", //社会责任说明
      home_video_img: "", //首页视频介绍图
      home_video_video: "", //首页视频
      home_talent_development_img: "", //首页人才图
      showCode: false,  // 二维码显示隐藏
      businessPage: 0, // 集团业务索引
      newsList: [], //所有新闻数据
      social_responsibility_data: [], //社会责任数据
      bannerPage: null, //顶部轮播图的索引
      bannerSwiper: null, //顶部轮播图
      businessSwiper: null, //集团业务轮播图
      currpage: 0, //顶部轮播当前显示页码
      totalSlides: 3, // 根据轮播图的总数设置
      newsWrapList: [], //新闻分类列表
      newsTitle:'',
      logo: "", // 公司logo
      code: "",  // 二维码
      bannerList: [],  // 首页PC轮播图
      newsTabIndex: 0, //新闻切换索引,一开始没点击,不能给null,默认给0,第一项
    };
  },
  created() {
    request("getMenuDataApi").then((red) => {
      // 头部数据
      this.logo = red.data.logo;
      this.code = red.data.company_set_qr_code;
    });
    request("getHomeDataApi").then((res) => {
      // console.log(res);
      // this.bannerFun();
      this.bannerList = res.data.home_phone_rotating_pictures_data;
      this.businessList = res.data.company_data;
      //等赋值后台数据后再调用轮播图初始化
      this.bannerFun();
      this.newsList = res.data.news_data;
      //循环三次，直接在循环的item对象里面加一个属性来接，或者data里面定义一个数组，来接循环的所有项
      //循环年月改变了item里面的值，那循环日会不会受影响
      this.newsList.forEach((ret) => {
        // this.newsWrapList.push(ret.news_data);
        ret.news_data.forEach((red,index)=>{
        //  red.newsTitle =red.title;
          red.createdAt = this.getYearMonth(red.created_at);
          red.createdAt_day = this.getDay(red.created_at);
        })
        //这只是最外层新闻列表的创建时间，不是每条新闻的创建时间
        // ret.createdAt = this.getYearMonth(ret.created_at);
        // console.log(ret.createdAt);
        // ret.createdAt_day = this.getDay(ret.created_at);
        // console.log(ret.createdAt_day);
      });
      // this.createdAt = this.getYearMonth(res.data.news_data.created_at); //新闻年月
      // this.createdAt_day = this.getDay(res.data.news_data.created_at);//新闻日
      // this.getYearMonth(res.data.news_data.created_at)
      this.social_responsibility_data = res.data.social_responsibility_data;
      this.home_company_name = res.data.home_company_name;
      this.home_new_name = res.data.home_new_name;
      this.home_social_responsibility_name =
        res.data.home_social_responsibility_name;
      this.home_social_responsibility_background =
        res.data.home_social_responsibility_background;
      this.home_social_responsibility_describe =
        res.data.home_social_responsibility_describe;
      this.home_video_img = res.data.home_video_img;
      this.home_video_video = res.data.home_video_video;
      this.home_talent_development_img = res.data.home_talent_development_img;
    });
    request("getMenuDataApi").then((red) => {
      // console.log(red);
      this.popList = red.data.menu_data;
    });
  },
  methods: {
    playVideo() {
      this.isPlaying = true;
      this.$refs.videoPlayer.play();
    },
    stopVideo() {
      this.isPlaying = false;
      this.$refs.videoPlayer.pause();
    },
    //新闻切换
    newsTab(index) {
      this.newsTabIndex = index;
    },
    // 提取年月的方法
    getYearMonth(dateTime) {
      // console.log('DateTime:', dateTime);
      const dateObj = new Date(dateTime);
      if (isNaN(dateObj.getTime())) {
        // 处理无效日期
        return "无效日期";
      }
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      return `${year}.${month}`;
    },
    // 提取日的方法
    getDay(dateTime) {
      const dateObj = new Date(dateTime);
      return dateObj.getDate();
    },
    //集团业务切换
    handleCarouselChange(index){
      console.log(index);
      this.businessPage=index;
    },
    bannerFun() {
      const that = this;
      this.$nextTick(() => {
        // 初始化 Swiper
        this.bannerSwiper = new Swiper(".swiper1", {
          // 可选参数
          direction: "horizontal",
          loop: true,
          slidesPerView: "auto",
          // spaceBetween: 20,
          // autoplay: true,
          autoplay: {
            delay: 3000, // 设置轮播间隔时间，单位为毫秒
            disableOnInteraction: false, // 用户操作后是否禁止自动轮播
          },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            // type: "bullets",
            // type:'fraction',
          },
          // 导航箭头
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          // 如果需要滚动条
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
          on: {
            slideChange: function () {
              that.currpage = this.realIndex + 1;
            },
          },
          //
          // on: {
          //   slideChange: this.updatePageIndicator,
          // },
        });
      });
    },
    selectItem(id, url,index) {
      console.log(this.$route.path);
      // 避免重复跳转当前页面报错
      if (this.$route.path== url){
        return this.showPopup=false;
      }
      // 点击选项时触发的方法
      // this.selectedItem = id;
      this.$router.push({
        path: url,
        query: { id },
      });
      // if (id == this.parentId ) {
      //   console.log("jinru");
      //   return;
      // }
      // if(!this.parentId){
      //   this.$router.push({
      //   path: this.popList[index].url,
      //   query: { id },
      // });
      // this.parentId=id;
      // return;
      // };
      // this.$router.push({
      //   path: url,
      //   query: { id },
      // });
      this.showPopup=false;
    },
    showPop() {
      console.log(123);
      this.showPopup = !this.showPopup;
      // this.showPopup=true;
    },
    
  },
  mounted() {
    const that = this;
    this.$nextTick(() => {
      // 初始化 Swiper
      this.mySwiper = new Swiper(".swiper-container", {
        // coverflowEffect: {
        //   rotate: 0,
        //   //每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        //   stretch: -79,
        //   // slide的位置深度。值越大z轴距离越远，看起来越小。
        //   depth: 500,
        //   // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modi
        //   modifier: 1,
        //   // 是否开启slide阴影
        //   slideShadows: true,
        // },
        // spaceBetween: 10, // 左右距离，每一张图之间的间距，不用在css中设置margin了
        // slidesPerView: "auto", // 默认一个屏幕显示几张图，必须auto！要不轮播到最后一张图时，右侧会是空白，很丑！
        // centeredSlides: true,
        // autoplay: {
        //   disableOnInteraction: false, // 操作swiper之后，是否禁止autoplay，默认为true，如不写此属性，在点击轮博或者活动轮播后，会停止自动轮播
        // },
        // // 下面两个属性一般是数据从后台请求后或者点击轮播图片跳转页面后，需要初始化swiper
        // observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        // observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        // 可选参数
        direction: "horizontal",
        loop: true,
        autoplay: true,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets",
          // type:'fraction',
        },
        // 导航箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // 如果需要滚动条
        // scrollbar: {
        //   el: ".swiper-scrollbar",
        // },
        on: {
          slideChange: function () {
            // console.log(that.mySwiper);
            // console.log(123);
            // console.log(this.realIndex);
            // console.log(this.activeIndex);
            that.currpage = this.realIndex + 1;
            // // that.currpage=(this.mySwiper.activeIndex)+1
            // console.log(this.currpage);
          },
        },
        //
        // on: {
        //   slideChange: this.updatePageIndicator,
        // },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.swiper-button-next,
.swiper-button-prev {
  transform: translateX(-50%);
  top: 50%;
  color: #ff0000;
}

.swiper-button-prev {
  left: 50px; /* 调整左箭头距离左边的距离 */
}

.swiper-button-next {
  right: 50px; /* 调整右箭头距离右边的距离 */
}
.page-indicator {
  font-size: 18px;
  color: rgba(26,32,44,0.64);
  // color:#1A202C;
}
::v-deep .swiper-container {
  overflow: hidden !important; /* 隐藏溢出内容 */
}

::v-deep .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.64);
  width: 4px;
  height: 4px;
}

/* 修改活动状态的分页器圆圈颜色 */
::v-deep .swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.64);
  width: 16px;
  height: 4px;
  border-radius: 38px;
}

::v-deep .swiper-container {
  overflow: hidden !important; /* 隐藏溢出内容 */
}

/* 如果需要隐藏垂直滚动条，添加以下样式 */
::v-deep .swiper-scrollbar {
  display: none !important;
}


</style>