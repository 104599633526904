<template>
  <div class="foot_wrap">
    <div class="font_content">
      <div class="foot_info_box">
        <div class="company_info">
          <div class="company_name">
            <span>{{ footList.company_set_company_name }}</span>
          </div>
          <div class="info_box">
            <div class="info_title">
              <img src="../../image/address_icon.png" alt="" class="info_icon" />
              <span>地址:</span>
            </div>
            <div class="info_content">
              <span>{{footList.company_set_company_address}}</span>
            </div>
          </div>
          <div class="info_box">
            <div class="info_title">
              <img src="../../image/phone_icon.png" alt="" class="info_icon" />
              <span>电话:</span>
            </div>
            <div class="info_content">
              <span>{{footList.company_set_company_phone}}</span>
              <span>{{footList.company_set_company_customer_service}}</span>
            </div>
          </div>
          <div class="info_box">
            <div class="info_title">
              <img src="../../image/email_icon.png" alt="" class="info_icon" />
              <span>邮箱:</span>
            </div>
            <div class="info_content">
              <span>{{footList.company_set_company_mailbox}}</span>
            </div>
          </div>
          <div class="info_box">
            <div class="info_title">
              <img src="../../image/fax_icon.png" alt="" class="info_icon" />
              <span>传真:</span>
            </div>
            <div class="info_content">
              <span>{{footList.company_set_company_fax}}</span>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="other_wrap">
          <div class="other_box">
            <div class="other_title"><span>关于我们</span></div>
            <div class="other_bot_box">
              <ul>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
              </ul>
              <ul>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
              </ul>
            </div>
          </div>
          <div class="other_box">
            <div class="other_title"><span>关于我们</span></div>
            <div class="other_bot_box">
              <ul>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
                <li>公司简介</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="foot_copyright">
        <span>{{footList.company_set_filings}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/request';
export default {
  data(){
    return{
      footList:{},
    }
  },
  created() {
    request("getFootDataApi").then((red) => {
      console.log(red);
      this.footList=red.data;
      
    });
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
