<template>
  <div class="boxs">
    <Header :parentId="+currentIndex" />
    <div class="banner">
      <img :src="about_us_advertisement_img" alt="" />
    </div>
    <div class="tab">
      <div class="tab_item">
        <div class="tab_img">
          <img :src="about_us_icon" alt="" />
        </div>
        <div class="tab_title">{{ about_us_name }}</div>
      </div>
      <div class="tab_item">
        <div class="tab_img">
          <img :src="about_us_course_icon" alt="" />
        </div>
        <div class="tab_title">{{ about_us_course_name }}</div>
      </div>
      <div class="tab_item">
        <div class="tab_img">
          <img :src="about_us_culture_icon" alt="" />
        </div>
        <div class="tab_title">{{ about_us_culture_name }}</div>
      </div>
      <div class="tab_item">
        <div class="tab_img">
          <img :src="about_us_commitment_icon" alt="" />
        </div>
        <div class="tab_title">{{ about_us_commitment_name }}</div>
      </div>
      <div class="tab_item">
        <div class="tab_img">
          <img :src="about_us_address_icon" alt="" />
        </div>
        <div class="tab_title">{{ about_us_address_name }}</div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about_box">
      <div class="about_title">{{ about_us_name }}</div>
      <div class="about_content">{{ about_us_content }}</div>
    </div>
    <!-- 发展历程 -->
    <div class="develop_box">
      <div class="develop_title">{{ about_us_course_name }}</div>
      <div class="develop_wrap">
        <div
          class="develop_item"
          v-for="(item, index) in course_data"
          :key="item.id"
        >
          <div class="develop_item_t" v-if="index % 2 == 1">
            <div class="item_t_year">{{ item.title }}</div>
            <div class="item_t_content">{{ item.content_text }}</div>
          </div>
          <div class="develop_line">
            <div class="line"></div>
            <div class="circle"></div>
          </div>
          <div class="develop_item_b" v-if="index % 2 == 0">
            <div class="item_b_year">{{ item.title }}</div>
            <div class="item_b_content">{{ item.content_text }}</div>
          </div>
        </div>
        <div class="one_line"><div class="line"></div></div>
      </div>
    </div>
    <!-- 企业文化 -->
    <div class="culture_box">
      <div class="culture_title">{{ about_us_culture_name }}</div>
      <div class="culture_wrap">
        <div
          :class="{ wrap_content: index == 0, wrap_img: index !== 0 }"
          v-for="(item, index) in culture_data"
          :key="item.id"
        >
          <img :src="item.img" alt="" />
        </div>
        <!-- v-if的写法 -->
        <!-- <div  v-for="(item,index) in culture_data" :key="item.id">
            <div v-if="index==0" class="wrap_content">
              <img :src="item.img" alt="">
            </div>
            <div v-if="index!==0" class="wrap_img">
              <img :src="item.img" alt="">
            </div>
          </div> -->

        <!-- <div class="wrap_img">
            <img src="" alt="">
          </div>
          <div class="wrap_img">
            <img src="" alt="">
          </div>
          <div class="wrap_img">
            <img src="" alt="">
          </div> -->
      </div>
    </div>
    <!-- 我们的承诺 -->
    <div class="ourPromise">
      <div class="left_box">
        <div class="title">{{ about_us_commitment_name }}</div>
        <div class="line"></div>
        <div class="content">{{ about_us_commitment_content }}</div>
      </div>
      <div class="right_box">
        <img :src="about_us_commitment_img" alt="" />
      </div>
    </div>
    <!-- 公司地址 -->
    <div class="company_add">
      <div class="company_add_title">{{ about_us_address_name }}</div>
      <div class="address_box">
        <div class="address_box_left" id="map"></div>
        <div class="address_box_right">
          <div class="info_title">{{ "发起请求" }}</div>
          <div class="info_title_c">
            我们能做什么？我们能做什么？我们能做什么？我们能做什么？
          </div>
          <div class="info_inputBox">
            <input type="text" placeholder="您的称呼"  v-model="submit_name"/>
            <div>
              <input
                type="text"
                placeholder="您的联系方式"
                v-model="phoneNumber"
              />
              <p v-if="!isValidPhoneNumber">请输入有效的手机号码。</p>
            </div>
          </div>
          <div class="info_content">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="留下您的需求"
              v-model="submit_need"
            ></textarea>
          </div>
          <div class="submit" @click="validatePhoneNumber">提交</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  
  <script>
import Header from "../../components/header/index.vue";
import Footer from "../../components/foot";
import AMapLoader from "@amap/amap-jsapi-loader";
import request from "@/api/request";
export default {
  components: { Header, Footer },
  data() {
    return {
      submit_name:'',
      submit_need:'',
      // list:[],
      map: null,
      phoneNumber: "",
      isValidPhoneNumber: true,
      currentIndex: "",
      about_us_advertisement_img: "", //广告图
      about_us_name: "", //关于我们模块名称
      about_us_icon: "", //关于我们模块图标
      about_us_content: "", //关于我们模块内容
      about_us_course_name: "", //发展历程模块名称
      about_us_course_icon: "", //发展历程模块图标
      about_us_culture_name: "", //企业文化模块名称
      about_us_culture_icon: "", //企业文化模块图标
      about_us_commitment_name: "", //我们的承诺模块名称
      about_us_commitment_icon: "", //我们的承诺模块图标
      about_us_commitment_img: "", //我们的承诺模块主图
      about_us_commitment_content: "", //我们的承诺模块内容
      about_us_address_name: "", //公司地址模块名称
      about_us_address_icon: "", //公司地址模块图标
      course_data: [], //发展历程数据
      culture_data: [], //企业文化数据
      footListData:{},//底部数据包含公司数据
      address_x: "",
      address_y: "",
      coordinate: "", //坐标
    };
  },
  created() {
    request("getAboutDataApi").then((res) => {
      // console.log(res);
      this.about_us_advertisement_img = res.data.about_us_advertisement_img;
      this.about_us_name = res.data.about_us_name;
      this.about_us_icon = res.data.about_us_icon;
      this.about_us_content = res.data.about_us_content;
      this.about_us_course_name = res.data.about_us_course_name;
      this.about_us_course_icon = res.data.about_us_course_icon;
      this.about_us_culture_name = res.data.about_us_culture_name;
      this.about_us_culture_icon = res.data.about_us_culture_icon;
      this.about_us_commitment_name = res.data.about_us_commitment_name;
      this.about_us_commitment_icon = res.data.about_us_commitment_icon;
      this.about_us_commitment_img = res.data.about_us_commitment_img;
      this.about_us_commitment_content = res.data.about_us_commitment_content;
      this.about_us_address_name = res.data.about_us_address_name;
      this.about_us_address_icon = res.data.about_us_address_icon;
      this.course_data = res.data.course_data;
      this.culture_data = res.data.culture_data;
    });
    request("getFootDataApi").then((red) => {
      console.log(red);
      this.footListData=red.data;
      this.coordinate = red.data.company_set_company_coordinate;
      const values = this.coordinate.split(",");
      this.address_x = values[0] || "";
      this.address_y = values[1] || "";
      console.log("Address X:", this.address_x);
      console.log("Address Y:", this.address_y);
      console.log(typeof this.address_x);
      console.log(typeof parseFloat(this.address_x));
      console.log(parseFloat(this.address_x));
      this.initAMap();
    });
    // request("getDemandDataApi").then((red) => {
    //   // console.log(red);
    // });
  },
  mounted() {
    // this.initAMap();
    this.currentIndex = this.$route.query.id; // 访问传递的id参数
    console.log(this.$route);
    // console.log(typeof this.$route.query.id);
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    async initAMap() {
      var content = [
        // '<div style="padding:0px 4px; width:300px; height:100px;background:#fff"><b>桐舟投资</b>',
        // "电话 : 010-84107000   邮编 : 100102",
        // "地址 : 北京市望京阜通东大街方恒国际中心A座16层</div></div>",
        `<div style="padding: 0px 4px; width: 300px; height: 80px; background: #fff;"><b>${this.footListData.company_set_company_name}</b><br>
        电话: ${this.footListData.company_set_company_phone}&nbsp;&nbsp; 邮编: ${this.footListData.company_set_company_mailbox}<br>
        地址: ${this.footListData.company_set_company_address}
        </div>`,
      ];
      const Amap = await AMapLoader.load({
        key: "eff0668dcd54182eae1ba79c88918815", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        willReadFrequently: true,
      });
      this.map = new Amap.Map("map", {
        // 设置地图容器id
        // viewMode: "3D", // 是否为3D地图模式
        zoom: 15, // 初始化地图级别
        center: [118.573175, 24.921004], // 初始化地图中心点位置
        // layers:[layer]
      });
      // 创建一个点标记 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(118.573175, 24.921004), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "北京",
      });
      // 将创建的点标记添加到已有的地图实例
      this.map.add(marker);
      // 创建信息窗体
      var infoWindow = new AMap.InfoWindow({
        // isCustom:true,
        // autoMove:true,
        position: new AMap.LngLat(118.573175, 24.921004),
        offset: new AMap.Pixel(0, -30),
        content: content.join("<br>"), //传入 dom 对象，或者 html 字符串
        anchor: "bottom-center", // 设置信息窗体的偏移量，以便于显示在标记的上方
      });
      // 绑定信息窗体到标记
      marker.on("click", () => {
        infoWindow.open(this.map, marker.getPosition());
      });
    },
    validatePhoneNumber() {
      // 定义手机号码的正则表达式
      const phoneRegex = /^[1][3-9][0-9]{9}$/;
      // console.log(this.phoneNumber);
      // 验证手机号码是否匹配正则表达式
      this.isValidPhoneNumber = phoneRegex.test(this.phoneNumber);
      if (phoneRegex.test(this.phoneNumber)&&this.submit_name&&this.submit_need) {
        const query = {user_name:this.submit_name,user_content_text:this.submit_need,user_phone:this.phoneNumber};
        request("getDemandDataApi", query).then((red) => {
          console.log(red);
          this.$message.success(red.msg)
        });
      }else{
        this.$message.error("请输入完整信息")
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "./index.scss";
</style>