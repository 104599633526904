import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../page/home/index.vue";
import Service from "../page/service/index.vue";
import News from "../page/news/index.vue";
import Contact from "../page/contact/index.vue";
import About from "../page/about/index.vue";
// import ServiceMB from "../page/serviceMB/index.vue";
// import NewsMB from "../page/newsMB/index.vue";
// import AboutMB from "../page/aboutMB/index.vue";
// import ContactMB from "../page/contactMB/index.vue";
// import HomeMB from "../page/homeMB/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { keepAlive: true }, // 缓存该页面组件
    prefetch: true, // 启用预加载
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    meta: { keepAlive: true }, // 缓存该页面组件
    prefetch: true, // 启用预加载
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: { keepAlive: true }, // 缓存该页面组件
    prefetch: true, // 启用预加载
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: { keepAlive: true }, // 缓存该页面组件
    prefetch: true, // 启用预加载
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { keepAlive: true }, // 缓存该页面组件
    prefetch: true, // 启用预加载
  },
  // {
  //   path: "/home",
  //   name: "HomeMB",
  //   component: HomeMB,
  // },
  // {
  //   path: "/serviceMB",
  //   name: "ServiceMB",
  //   component: ServiceMB,
  // },
  // {
  //   path: "/newsMB",
  //   name: "NewsMB",
  //   component: NewsMB,
  // },
  // {
  //   path: "/aboutMB",
  //   name: "AboutMB",
  //   component: AboutMB,
  // },
  // {
  //   path: "/contactMB",
  //   name: "ContactMB",
  //   component: ContactMB,
  // },
  // 添加其他路由
];

const router = new VueRouter({
  routes,
});

export default router;
