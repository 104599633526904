<template>
  <!-- <router-view></router-view> -->
  <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <!-- <router-view v-if="$route.meta.keepAlive"></router-view> -->

</template>

<script>
export default {
  name: "App",
  mounted() {
    //  console.log(window.outerWidth);
    //  return
    var width = window.outerWidth;

    // if (width >= 750) {
    //   width = 750;
    // }
    if (width <= 750) {
      var auto = (Number(width) * 100) / 750;
      var myElement = document.querySelector("html");
      console.log(myElement);
      myElement.style.fontSize = auto + "px";
      console.log(myElement.style.fontSize);
      console.log(auto);
    }
    // var auto = Number(width) * 100 / 750;
    // var myElement  =document.querySelector("html")
    // console.log(myElement );
    // myElement.style.fontSize=auto+'px'
    // console.log(myElement.style.fontSize);
    // console.log(auto);
    // $('html').css('font-size', auto);
  },
};
</script>

<style>
@import "./reset.css";
@import "./border.css";
/* 取消页面滚动条 */
::-webkit-scrollbar {
  height: 0;
  width: 0;
  display: none;
}
body::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
img{
  object-fit: cover;
}
</style>
