<template>
  <div style="width: 100vw; background-color: #fff">
    <Header :parentId="currentIndex" />
    <div class="content_box"><img :src="new_advertisement_img" alt="" /></div>
    <div class="company-news" v-if="news_classify_data.length > 0">
      <div class="title">
        <p class="title_t">{{ news_classify_data[0].name }}</p>
        <p class="title_c">HOT NEWS</p>
        <div class="line"></div>
      </div>
      <div class="company-news-box" v-if="news_classify_data.length > 0">
        <div class="box_left">
          <div class="news_img_l"><img :src="news_classify_data[0].news_data.data[0].img" alt="" style="background-color: #ddd" /></div>
          <p class="news_left_p1">{{ news_classify_data[0].news_data.data[0].title }}</p>
          <p class="news_left_p2">{{ news_classify_data[0].news_data.data[0].describe }}</p>
          <p class="news_left_time">{{ news_classify_data[0].news_data.data[0].created_at }}</p>
        </div>
        <div class="box_right">
          <div class="box_right_wrap" v-for="(item, index) in news_classify_data[0].news_data.data" :key="item.id" :style="{ display: index == 0 ? 'none' : 'block' }">
            <div class="news_img_r"><img :src="item.img" alt="" /></div>
            <p class="news_right_p1">{{ item.title }}</p>
            <p class="news_right_p2">{{ item.describe }}</p>
            <p class="news_right_time">{{ item.created_at }}</p>
          </div>
          <div class="box_right_wrap"></div>
          <div class="box_right_wrap"></div>
          <div class="box_right_wrap"></div>
        </div>
      </div>
      <!-- 第一项新闻分页器 -->
      <div class="pagination">
        <el-pagination
          :current-page="news_classify_data[0].page"
          background
          layout="total,prev, pager, next, jumper"
          :total="news_classify_data[0].total"
          @current-change="(page) => currentChange(page, 0, news_classify_data[0].id, 5)"
          :page-size="5"
          :page-sizes="pageSizes1"
        >
        </el-pagination>
      </div>
    </div>
    <div class="hot-news" v-for="(item, index) in news_classify_data" :key="item.id" :style="{ display: index == 0 ? 'none' : 'block' }">
      <div class="title">
        <p class="title_t">{{ item.name }}</p>
        <p class="title_c">HOT NEWS</p>
        <div class="line"></div>
      </div>
      <div class="hot_news_box">
        <div class="hot_news_wrap" v-for="items in item.news_data.data" :key="items.id">
          <div class="wrap_left">
            <img :src="items.img" alt="" />
          </div>
          <div class="wrap_right">
            <div class="wrap_right_title">{{ items.title }}</div>
            <div class="wrap_right_content">{{ items.describe }}</div>
            <div class="detail">详情>></div>
          </div>
        </div>
      </div>
      <!-- 除了第一项循环分页器 -->
      <div class="pagination">
        <el-pagination
          @current-change="(page) => currentChange(page, index, item.id)"
          background
          :page-sizes="pageSizes"
          :page-size="8"
          layout="total,prev, pager, next, jumper"
          :total="item.total"
          :current-page="item.page"
        >
        </el-pagination>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header/index.vue";
import Footer from "../../components/foot";
import request from "../../api/request";
export default {
  components: { Header, Footer },
  data() {
    return {
      pageSizes: [8],
      pageSizes1: [5],
      new_advertisement_img: "",
      company_totalItems: 100, // company_news_data(第一项)总条数
      company_pageSize: 5, // 每页显示条数
      company_currentPage: 1, // 当前页码

      hot_totalItems: 100,
      hot_currentPage: 8,
      hot_pageSize: 1,

      hot_news_data: [], //新闻除了第一个板块的新闻数据(其他新闻)
      hot_news_data_array: [], //新闻除了第一个板块的新闻数据(其他新闻)单项
      news_id: "",
      // allData: [],//所有数据
      // newsList:[1,2,3,4],
      // hotNewsList:[1,2,3,4,5,6,7,8],
      currentIndex: 3, //导航栏的索引,新闻页
      news_classify_data: [], //新闻分类模块
      company_news_data: [], //新闻第一个板块的新闻数据(集团新闻)
    };
  },
  created() {
    request("getNewsDataApi").then((res) => {
      console.log(res);
      this.new_advertisement_img = res.data.new_advertisement_img;
      this.news_classify_data = res.data.news_data;
      this.news_classify_data.forEach((item) => {
        item.page = 1;
        item.total = item.news_data.total;
      });
      console.log(this.news_classify_data, "新闻");
    });
  },

  mounted() {
    this.currentIndex = this.$route.query.id; // 访问传递的id参数
  },
  methods: {
    //分页切换
    async currentChange(page, index, news_group_id, limit) {
      //page 点击了哪一页
      //index 新闻体的index
      //news_group_id 被点击的新闻体的id
      //limit 要请求几条数据
      const query = {
        limit,
        page,
        news_group_id,
      };
      const res = await request("getNewsListApi", query);
      this.news_classify_data[index].news_data.data = res.data.data;
      //重新赋值总条数
      this.$set(this.news_classify_data[index], "total", res.data.total);
      //改变当前页数
      this.$set(this.news_classify_data[index], "page", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
