<template>
  <div style="width: 100vw; background-color: #fff">
    <Header :parentId="currentIndex"/>
    <div class="content">
      <img :src="company_advertisement_img" alt="" />
    </div>
    <div class="service-box" >
      <div class="title">集团业务</div>
      <div class="wrap">
        <div class="wrap-box" v-for="item in company_data" :key="item.id" :style="{backgroundImage: 'url(company_advertisement_img)'}">
          <div class="img"><img :src="item.img" alt=""></div>
          <div class="wrap_title">{{item.title}}</div>
          <div class="wrap_content">{{item.describe}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="text-sum">
      <input
        class="text-sum-ipt"
        type="text"
        v-model.number="sum.num1"
        placeholder="请输入数字1"
      />
      <div>+</div>
      <input
        class="text-sum-ipt"
        type="text"
        v-model.number="sum.num2"
        placeholder="请输入数字2"
      />
      <div>结果是:{{ sum.result }}</div>
    </div>
    <button type="primary" @click="calcSum" style="width: 100px;height: 100px;">求和</button> -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header/index.vue";
import Footer from "../../components/foot/index.vue";
import request from '@/api/request';
export default {
  components: { Header, Footer },
  data() {
    return {
      list: [1, 2, 3, 4, 5],
      sum: {
        num1: 0,
        num2: 0,
        result: 0,
      },
      currentIndex:2,
      company_advertisement_img:'',//轮播图
      company_data:[],
    };
  },
  created() {
    request("getCompanyDataApi").then((res) => {
      console.log(res);
      this.company_advertisement_img=res.data.company_advertisement_img;
      this.company_data=res.data.company_data;
    });
  },
  mounted() {
    this.currentIndex=this.$route.query.id; // 访问传递的id参数
  },
//   mounted(){
//     this.$yjs()
//   },
//   methods: {
//     calcSum() {
//       this.sum.result = this.$utils.sum(this.sum.num1, this.sum.num2);
//     },
//   },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>