<template>
  <div style="width: 100vw; background-color: #fff">
    <div class="content">
      <div class="header_left_content">
        <div class="logo">
          <img :src="logo" alt="" class="img">
        </div>
        <div class="line"></div>
        <ul class="classif_wrap">
          <li
            class="class_item"
            v-for="(item,index) in list"
            :key="item.name"
            :class="{ highlighted: item.id == parentId }"
            @click="selectItem(item.id, item.url,index)"
          >
          <div class="choosed_line" v-if="item.id == parentId"></div>
          <div class="class_item_img"><img :src="item.icon" alt="" v-if="item.id == parentId"></div>
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="header_right_content">
        <!-- <div class="language">语言</div> -->
        <!-- <div class="line"></div> -->
        <div class="urCode" @click="showCodeImg">
          <img src="../../image/codeY.png" alt="" class="img" />
          <div class="codePopup" v-if="showCode">
            <img :src="company_set_qr_code" alt="" class="codeImg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  props: {
    parentId: {
      // type: Number,
      required: false,
    },
  },
  // computed: {
  //   isIndexEqual() {
  //     // 判断父组件传递的索引是否与子组件自身的索引相等
  //     this.list.forEach((item,index)=>{
  //         const currentIndex= index
  //     })
  //     return this.parentIndex ==this.currentIndex ;
  //   },
  // },
  data() {
    return {
      showCode: false,
      list: [],
      company_set_qr_code:'',//二维码
      logo:'',

    };
  },
  created() {
    request("getMenuDataApi").then((red) => {
      console.log(red);
      this.list = red.data.menu_data;
      this.company_set_qr_code=red.data.company_set_qr_code;
      this.logo=red.data.logo;
    });
  },
  methods: {
    showCodeImg() {
      this.showCode = !this.showCode;
    },
    //还有一种方法是子组件对父组件触发一个事件,传参,父组件调用后传值给子组件,再来对比
    //动态的导航栏,动态渲染的数据,只能通过一个不受影响的唯一值来做判断,比如id.那index会改变,只能用于写死的时候
    selectItem(id, url,index) {
      if (id == this.parentId ) {
        console.log("jinru");
        return;
      }
      if(!this.parentId){
        this.$router.push({
        path: this.list[index].url,
        query: { id },
      });
      this.parentId=id;
      return;
      };
      this.$router.push({
        path: url,
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
// .highlighted {
//   background-color: red;
// }
</style>
