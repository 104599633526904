<template>
  <div class="home">
    <Header :parentId="currentIndex" />
    <div
      class="banner"
      :style="{
        backgroundImage: `url('${contact_us_advertisement_img}')`,
        backgroundSize: '100% 100%',
      }"
    >
      <p class="banner_title">联系我们</p>
      <p class="banner_content">
        我们致力于为您提供个性化的服务。任何问题请联系
      </p>
      <div class="banner_btn">在线客服</div>
    </div>
    <div class="contactUs">
      <div class="contactUs_title">联系我们</div>
      <div class="contactUs_box">
        <div class="box_left">
          <p class="customer_phone">客服电话</p>
          <div class="phone_num">
            <img src="../../image/call-icon.png" alt="" />
            <span>{{ footListData.company_set_company_phone }}</span>
          </div>
          <div class="customer_text">
            为您提供VIP解决方案、行业合作、投诉故障及业务咨询(7*24小时支持)
          </div>
        </div>
        <div class="mid_line"></div>
        <div class="box_right">
          <p class="customer_online">在线客服</p>
          <p class="online_text">
            智能诊断，即时回复，为您提供快速便捷的服务体验
          </p>
          <div class="online_btn">立即咨询</div>
        </div>
      </div>
    </div>
    <div class="company_add">
      <div class="company_add_title">公司地址</div>
      <div class="address_box">
        <div class="address_box_left" id="map"></div>
        <div class="address_box_right">
          <div class="info_title">{{ "发起请求" }}</div>
          <div class="info_title_c">
            我们能做什么？我们能做什么？我们能做什么？我们能做什么？
          </div>
          <div class="info_inputBox">
            <input type="text" placeholder="您的称呼" v-model="submit_name" />
            <div>
              <input
                type="text"
                placeholder="您的联系方式"
                v-model="phoneNumber"
              />
              <p v-if="!isValidPhoneNumber">请输入有效的手机号码。</p>
            </div>
          </div>
          <div class="info_content">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              placeholder="留下您的需求"
              v-model="submit_need"
            ></textarea>
          </div>
          <div class="submit" @click="validatePhoneNumber">提交</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
  
<script>
import Header from "../../components/header/index.vue";
import Footer from "../../components/foot";
import AMapLoader from "@amap/amap-jsapi-loader";
import request from "@/api/request";
export default {
  components: { Header, Footer },
  data() {
    return {
      submit_name: "",
      submit_need: "",
      map: null,
      phoneNumber: "",
      isValidPhoneNumber: true,
      currentIndex: 4,
      contact_us_advertisement_img: "",
      footListData: {},
      address_x: "",
      address_y: "",
      coordinate: "", //坐标
    };
  },
  created() {
    // this.initAMap();
    request("getContactDataApi").then((res) => {
      console.log(res);
      this.contact_us_advertisement_img = res.data.contact_us_advertisement_img;
    });
    request("getFootDataApi").then((red) => {
      console.log(red);
      this.footListData = red.data;
      this.coordinate = red.data.company_set_company_coordinate;
      const values = this.coordinate.split(",");
      this.address_x = values[0] || "";
      this.address_y = values[1] || "";
      console.log("Address X:", this.address_x);
      console.log("Address Y:", this.address_y);
      console.log(typeof this.address_x);
      console.log(typeof parseFloat(this.address_x));
      console.log(parseFloat(this.address_x));
      this.initAMap();
    });
  },
  mounted() {
    // this.initAMap();
    this.currentIndex = this.$route.query.id; // 访问传递的id参数
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    async initAMap() {
      // const that=this;
      var content = [
        `<div style="padding: 0px 4px; width: 300px; height: 80px; background: #fff;"><b>${this.footListData.company_set_company_name}</b><br>
        电话: ${this.footListData.company_set_company_phone}&nbsp;&nbsp; 邮编: ${this.footListData.company_set_company_mailbox}<br>
        地址: ${this.footListData.company_set_company_address}
        </div>`,
      ];
      const Amap = await AMapLoader.load({
        key: "eff0668dcd54182eae1ba79c88918815", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        willReadFrequently: true,
      });
      this.map = new Amap.Map("map", {
        // 设置地图容器id
        // viewMode: "3D", // 是否为3D地图模式
        zoom: 15, // 初始化地图级别
        center: [+this.address_x, +this.address_y], // 初始化地图中心点位置
        // layers:[layer]
      });
      // 创建一个点标记 Marker 实例：
      var marker = new AMap.Marker({
        position: new AMap.LngLat(+this.address_x, +this.address_y), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "北京",
      });
      // 将创建的点标记添加到已有的地图实例
      this.map.add(marker);
      // 创建信息窗体
      var infoWindow = new AMap.InfoWindow({
        // isCustom:true,
        // autoMove:true,
        position: new AMap.LngLat(+this.address_x, +this.address_y),
        offset: new AMap.Pixel(0, -30),
        content: content.join("<br>"), //传入 dom 对象，或者 html 字符串
        anchor: "bottom-center", // 设置信息窗体的偏移量，以便于显示在标记的上方
      });
      // 绑定信息窗体到标记
      marker.on("click", () => {
        infoWindow.open(this.map, marker.getPosition());
      });
      // AMapLoader.load({
      //   key: "eff0668dcd54182eae1ba79c88918815", // 申请好的Web端开发者Key，首次调用 load 时必填
      //   version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //   plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      // })
      //   .then((AMap) => {
      //     this.map = new AMap.Map("map", {
      //       // 设置地图容器id
      //       // viewMode: "3D", // 是否为3D地图模式
      //       zoom: 11, // 初始化地图级别
      //       center: [116.397428, 39.90923], // 初始化地图中心点位置
      //     });
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
    validatePhoneNumber() {
      // 定义手机号码的正则表达式
      const phoneRegex = /^[1][3-9][0-9]{9}$/;
      // console.log(this.phoneNumber);
      // 验证手机号码是否匹配正则表达式
      this.isValidPhoneNumber = phoneRegex.test(this.phoneNumber);
      if (
        phoneRegex.test(this.phoneNumber) &&
        this.submit_name &&
        this.submit_need
      ) {
        const query = {
          user_name: this.submit_name,
          user_content_text: this.submit_need,
          user_phone: this.phoneNumber,
        };
        request("getDemandDataApi", query).then((red) => {
          console.log(red);
          this.$message.success(red.msg);
        });
      } else {
        this.$message.error("请输入完整信息");
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "./index.scss";
#map {
  width: 500px;
  height: 334px;
}
</style>