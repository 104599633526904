<template>
  <div class="home" style="width: 100vw; background-color: #fff">
    <div class="popBox" v-if="showPopBox" @click="handlePopCLick">
      <div class="popLogo"><img src="../../image/download.png" alt=""></div>
    </div>
    <!-- <Header /> -->
    <div class="head">
      <div class="content">
        <div class="header_left_content">
          <div class="logo">
            <img class="img" :src="logo" />
          </div>
          <div class="line"></div>
          <ul class="classif_wrap">
            <li
              class="class_item"
              v-for="(item, index) in list"
              :key="item.name"
              @click="selectItem(item.id, item.url, index)"
            >
              <div class="choosed_line" v-if="index == 0"></div>
              <div class="class_item_img">
                <img :src="item.icon" alt="" v-if="index == 0" />
              </div>
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="header_right_content">
          <!-- <div class="language">语言</div> -->
          <!-- <div class="line"></div> -->
          <div class="urCode" @click="showCodeImg">
            <img src="../../image/code.png" alt="" class="img" />
            <div class="codePopup" v-if="showCode">
              <img :src="code" alt="" class="codeImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="banner">
        <div class="swiper-container swiper1">
          <!-- 需要的额外容器 -->
          <div class="swiper-wrapper">
            <!-- 幻灯片内容 -->
            <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
              <img :src="item.img" alt="" />
            </div>
            <!-- <div class="swiper-slide">
              <img :src="bannerList.img" alt="" />
            </div>
            <div class="swiper-slide">
              <img :src="bannerList.img" alt="" />
            </div> -->
            <!-- 如果需要更多幻灯片，继续添加 -->
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>

          <!-- 如果需要导航按钮 -->
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div> -->

          <!-- 如果需要滚动条 -->
          <div class="swiper-scrollbar"></div>
        </div>
        <div class="page-indicator">
          <!-- 显示当前页码 -->
          {{ currpage }} / {{ bannerList.length }}
        </div>
        <div class="banner_btn_l" @click="prevSlide1">
          <img src="../../image/banner-icon-left.png" alt="" />
        </div>
        <div class="banner_btn_r" @click="nextSlide1">
          <img src="../../image/banner-icon-right.png" alt="" />
        </div>
      </div>
      <!-- 集团业务 -->
      <div class="business">
        <div class="business_left">
          <div class="business_titel">集团业务</div>
          <div class="business_name_box">
            <div
              class="business_name"
              v-for="(items, indexs) in businessList"
              :key="indexs"
              :style="{
                color:
                  businessPage == indexs ? '#BC8F22' : 'rgba(188,143,34,0.16)',
              }"
              @click="updateBusinessPage(indexs)"
            >
              {{ items.name }}
            </div>
          </div>
          <div
            class="business_content"
            v-for="(item, index) in businessList"
            :key="item.id"
          >
            <div class="company_name" v-if="index == businessPage">
              {{ item.title }}
            </div>
            <div class="company_content" v-if="index == businessPage">
              {{ item.describe }}
            </div>
          </div>
          <div class="business_more">了解更多</div>
          <div class="business_btn">
            <div class="business_btn_l" @click="prevSlide">
              <img src="../../image/bussi-icon-l.png" alt="" />
            </div>
            <div class="business_btn_r" @click="nextSlide">
              <img src="../../image/bussi-icon-right.png" alt="" />
            </div>
          </div>
        </div>
        <div class="business_right">
          <div class="swiper-container swiper2">
            <!-- 需要的额外容器 -->
            <div class="swiper-wrapper">
              <!-- 幻灯片内容 -->
              <div
                class="swiper-slide"
                v-for="item in businessList"
                :key="item.id"
              >
                <img :src="item.img" alt="" />
              </div>
              <!-- 如果需要更多幻灯片，继续添加 -->
            </div>
          </div>
        </div>
        <div class="right_btn" @click="nextSlide">
          <img src="../../image/bussi-icon-r.png" alt="" />
        </div>
      </div>
      <!-- 新闻动态 -->
      <div class="news">
        <div class="news_name">
          <div class="news_name_l">
            <div class="left_name">{{ home_new_name }}</div>
            <div class="left_more">更多动态>></div>
          </div>
          <div class="news_name_r">
            <div
              class="company_news"
              v-for="(item, index) in newsList"
              :key="item.id"
              @click="newsTab(index)"
              :style="{ color: index == newsTabIndex ? '#BC8F22' : ' #1A202C' }"
            >
              {{ item.name }}
            </div>
            <!-- <div class="media_news">媒体新闻</div> -->
          </div>
        </div>
        <!-- 必须当newsList有数据的时候才能获取到里面的news_data ,刚开始页面渲染时没数据,获取不到报错 v-if="newsList.length > 0"-->
        <div class="news_box" v-if="newsList.length > 0">
          <div class="news_time">
            最新动态:{{ newsList[newsTabIndex].news_data[0].created_at }}
          </div>
          <div class="news_title">
            {{ newsList[newsTabIndex].news_data[0].title }}
          </div>
          <div class="more_news">查看详情</div>
        </div>
        <div class="news_wrap" v-if="newsList.length > 0">
          <div
            class="news_wrap_item"
            v-for="(item, index) in newsList[newsTabIndex].news_data"
            :key="item.id"
            :style="{ display: index == 0 ? 'none' : 'block' }"
          >
            <!-- 为了v-for和v-if不同时存在，多加一层盒子包裹循环项 -->
            <div>
              <div class="item_time">
                <div class="item_time_day">{{ item.createdAt_day }}</div>
                <div class="item_time_yearMonth">{{ item.createdAt }}</div>
              </div>
              <div class="item_title">
                {{ item.title }}
              </div>
              <div class="item_content">
                <!-- {{item.news_data[index].describe}} -->
                {{ newsList[newsTabIndex].news_data[index].describe }}
                <!-- {{ item.describe }} -->
              </div>
              <div class="item_detail">查看详情</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 社会责任 -->
      <div
        class="society"
        :style="{
          backgroundImage: 'url(' + home_social_responsibility_background + ')',
        }"
      >
        <div class="society_top">
          <div class="society_name">{{ home_social_responsibility_name }}</div>
          <div class="society_title">
            {{ home_social_responsibility_describe }}
          </div>
          <div class="society_detail">查看详情</div>
        </div>
        <div class="society_box">
          <div
            class="society_box_l"
            v-for="item in social_responsibility_data"
            :key="item.id"
          >
            <div class="item_left">
              <div class="item_left_img"><img :src="item.icon" alt="" /></div>
              <div class="item_left_name">{{ item.name }}</div>
            </div>
            <div class="item_right">
              <div class="item_right_title">{{ item.title }}</div>
              <div class="item_right_content">
                {{ item.content_text }}
              </div>
            </div>
          </div>
          <!-- <div class="society_box_r">
            <div class="item_left">
              <div class="item_left_img"><img src="" alt="" /></div>
              <div class="item_left_name">合作伙伴</div>
            </div>
            <div class="item_right">
              <div class="item_right_title"></div>
              <div class="item_right_content"></div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 宣传视频 -->
      <div
        class="video_box"
      >
        <!-- <video :src="home_video_video" autoplay type="video/mp4"></video> -->
        <div class="play_btn" @click="playVideo" v-if="!isPlaying"><img src="../../image/play_btn.png" alt=""></div>
        <!-- 视频主图 -->
        <img
          v-if="!isPlaying"
          :src="home_video_img"
          alt=""
        />
        <!-- 视频播放器 -->
        <video v-if="isPlaying" ref="videoPlayer" controls @ended="stopVideo">
          <source :src="home_video_video" type="video/mp4" />
        </video>
      </div>
      <!-- 人才加入宣传 -->
      <div class="person_img">
        <img :src="home_talent_development_img" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import Header from "../../components/header/index.vue";
import Footer from "../../components/foot";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import request from "../../api/request";

export default {
  components: { Footer },
  data() {
    return {
      showPopBox:false,
      isPlaying: false,
      imageUrl: "",
      // createdAt: "", //时间(年月)
      // createdAt_day:'',//时间(日)
      home_company_name: "", //集团业务模块
      home_new_name: "", //新闻动态模块
      home_social_responsibility_name: "", //社会责任模块
      home_social_responsibility_background: "", //社会责任背景图
      home_social_responsibility_describe: "", //社会责任说明
      home_video_img: "", //首页视频介绍图
      home_video_video: "", //首页视频
      home_talent_development_img: "", //首页人才图

      selectedItem: null, // 存储当前选中的项的索引
      businessList: [
        //集团业务title,文本
        { name: "123", id: "111" },
        { name: "456", id: "222" },
        { name: "789", id: "333" },
      ],
      // businessNameList: [//集团业务公司名
      //   { name: "123", id: "111" },
      //   { name: "456", id: "222" },
      //   { name: "789", id: "333" },
      // ],
      showCode: false, // 二维码显示隐藏
      businessPage: 0, // 集团业务索引
      newsList: [], //所有新闻数据
      social_responsibility_data: [], //社会责任数据
      bannerPage: null, //顶部轮播图的索引
      bannerSwiper: null, //顶部轮播图
      businessSwiper: null, //集团业务轮播图
      currpage: 0, //顶部轮播当前显示页码
      totalSlides: 3, // 根据轮播图的总数设置
      newsWrapList: [], //新闻分类列表
      newsTitle: "",
      // 导航栏
      list: [
        { name: "首页" },
        { name: "关于我们" },
        { name: "产品与服务" },
        { name: "新闻中心" },
        { name: "联系我们" },
      ],
      logo: "", // 公司logo
      code: "", // 二维码
      bannerList: [], // 首页PC轮播图
      newsTabIndex: 0, //新闻切换索引,一开始没点击,不能给null,默认给0,第一项
    };
  },
  created() {
    request("getHomeDataApi").then((res) => {
      // console.log(res);
      // this.bannerFun();
      this.bannerList = res.data.home_pc_rotating_pictures_data;
      console.log(this.bannerList);
      this.businessList = res.data.company_data;
      //等赋值后台数据后再调用轮播图初始化
      this.bannerFun();
      this.newsList = res.data.news_data;
      //循环三次，直接在循环的item对象里面加一个属性来接，或者data里面定义一个数组，来接循环的所有项
      //循环年月改变了item里面的值，那循环日会不会受影响
      this.newsList.forEach((ret) => {
        // this.newsWrapList.push(ret.news_data);
        ret.news_data.forEach((red, index) => {
          //  red.newsTitle =red.title;
          red.createdAt = this.getYearMonth(red.created_at);
          red.createdAt_day = this.getDay(red.created_at);
        });
        //这只是最外层新闻列表的创建时间，不是每条新闻的创建时间
        // ret.createdAt = this.getYearMonth(ret.created_at);
        // console.log(ret.createdAt);
        // ret.createdAt_day = this.getDay(ret.created_at);
        // console.log(ret.createdAt_day);
      });
      // this.createdAt = this.getYearMonth(res.data.news_data.created_at); //新闻年月
      // this.createdAt_day = this.getDay(res.data.news_data.created_at);//新闻日
      // this.getYearMonth(res.data.news_data.created_at)
      this.social_responsibility_data = res.data.social_responsibility_data;
      this.home_company_name = res.data.home_company_name;
      this.home_new_name = res.data.home_new_name;
      this.home_social_responsibility_name =
        res.data.home_social_responsibility_name;
      this.home_social_responsibility_background =
        res.data.home_social_responsibility_background;
      this.home_social_responsibility_describe =
        res.data.home_social_responsibility_describe;
      this.home_video_img = res.data.home_video_img;
      this.home_video_video = res.data.home_video_video;
      this.home_talent_development_img = res.data.home_talent_development_img;
    });
    request("getMenuDataApi").then((red) => {
      // 头部数据
      console.log(red);
      this.logo = red.data.logo;
      this.code = red.data.company_set_qr_code;
      this.list = red.data.menu_data;
    });
    // request("getFootDataApi").then((red) => {
    //   this.footData=red.data
    //   console.log(red);

    // });
  },
  mounted() {
    // this.showPopBox=true;
    // document.documentElement.style.overflow = 'hidden';//禁止页面滚动
    //每次关闭和重新打开浏览器标签页时，sessionStorage 中的标志都会被清空，从而弹窗会重新显示
    if (!sessionStorage.getItem('hasSeenPopUp')) {
      this.showPopBox = true;
      document.documentElement.style.overflow = 'hidden'; 
    }
  },
  methods: {
    handlePopCLick(){
      this.showPopBox=false;
      document.documentElement.style.overflow = ''; // 恢复页面滚动
      sessionStorage.setItem('hasSeenPopUp', 'true');
    },
    playVideo() {
      this.isPlaying = true;
      this.$refs.videoPlayer.play();
    },
    stopVideo() {
      this.isPlaying = false;
      this.$refs.videoPlayer.pause();
    },
    //新闻切换
    newsTab(index) {
      this.newsTabIndex = index;
    },
    // 提取年月的方法
    getYearMonth(dateTime) {
      // console.log('DateTime:', dateTime);
      const dateObj = new Date(dateTime);
      if (isNaN(dateObj.getTime())) {
        // 处理无效日期
        return "无效日期";
      }
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      return `${year}.${month}`;
    },
    // 提取日的方法
    getDay(dateTime) {
      const dateObj = new Date(dateTime);
      return dateObj.getDate();
    },
    // 封装顶部和集团业务轮播图的方法
    bannerFun() {
      const that = this;
      this.$nextTick(() => {
        // 初始化 Swiper
        this.bannerSwiper = new Swiper(".swiper1", {
          // 可选参数
          direction: "horizontal",
          loop: true,
          slidesPerView: "auto",
          // spaceBetween: 20,
          // autoplay: true,
          autoplay: {
            delay: 3000, // 设置轮播间隔时间，单位为毫秒
            disableOnInteraction: false, // 用户操作后是否禁止自动轮播
          },
          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            clickableClass: "my-pagination-clickable",
            // type: "bullets",
            // type:'fraction',
          },
          // 导航箭头
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          // 如果需要滚动条
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
          on: {
            slideChange: function () {
              that.currpage = this.realIndex + 1;
            },
          },
          //
          // on: {
          //   slideChange: this.updatePageIndicator,
          // },
        });
        this.businessSwiper = new Swiper(".swiper2", {
          // 可选参数
          direction: "horizontal",
          loop: true,
          slidesPerView: "auto",
          spaceBetween: 20,
          // autoplay: true,
          autoplay: {
            delay: 3000, // 设置轮播间隔时间，单位为毫秒
            disableOnInteraction: false, // 用户操作后是否禁止自动轮播
          },
          // 导航箭头
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // },

          on: {
            slideChange: function () {
              // console.log(that.mySwiper);
              // console.log(123);
              // console.log(this.realIndex);
              // console.log(this.activeIndex);
              // that.currpage = this.realIndex + 1;
              that.businessPage = this.realIndex;
              // console.log(that.businessPage);
              // // that.currpage=(this.mySwiper.activeIndex)+1
              // console.log(this.currpage);
            },
          },
        });
      });
    },
    // selectItem(indexes) {
    //   // 点击选项时触发的方法
    //   this.selectedItem = indexes;
    // },
    selectItem(id, url, index) {
      // 当点击自己的时候,避免出现路由冗余报错
      if (index == 0) return;
      // 点击选项时触发的方法
      this.selectedItem = id;
      this.$router.push({
        path: url,
        query: { id },
      });
      // if(index==0){
      //   this.$router.push({
      //   path: this.list[index].url,
      //   query: { id },
      // });
      // return;
      // }
    },
    showCodeImg() {
      this.showCode = !this.showCode;
    },
    updateBusinessPage(index) {
      // 点击 businessNameList 更新 businessPage
      this.businessPage = index;

      // 同时更新轮播图和 businessList
      this.businessSwiper.slideTo(index); // 将轮播图切换到指定索引
      // 可根据需要更新 businessList 的数据
      // this.businessList = updatedBusinessListData;
    },
    prevSlide() {
      // 调用 swiper2 的 prev 方法
      this.businessSwiper.slidePrev();
    },
    nextSlide() {
      // 调用 swiper2 的 next 方法
      this.businessSwiper.slideNext();
    },
    prevSlide1() {
      // 调用 swiper1 的 prev 方法
      this.bannerSwiper.slidePrev();
    },
    nextSlide1() {
      // 调用 swiper1 的 next 方法
      this.bannerSwiper.slideNext();
    },
   
  },
  
  // methods: {
  //   updatePageIndicator() {
  //     // 更新当前页码
  //     this.currentPage = this.mySwiper.realIndex + 1;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";

.swiper-button-next,
.swiper-button-prev {
  transform: translateX(-50%);
  top: 50%;
  color: #ff0000;
}

.swiper-button-prev {
  left: 50px; /* 调整左箭头距离左边的距离 */
}

.swiper-button-next {
  right: 50px; /* 调整右箭头距离右边的距离 */
}
.page-indicator {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.64);
  // color: #fff;
}
// .swiper-container {
//   width: 100%;
//   height: 100%;
// }
// .my-pagination-clickable {
//   width: 120px !important;
//   position: absolute !important;
//   display: flex;
// bottom: 80px !important;
// right: 830px !important;
// }
// .swiper-pagination {
//   width: 400px;
//   position: absolute;
//   right: 0px; /* 调整右侧距离 */
//   bottom: 30px; /* 调整底部距离 */
//   // display: flex;
//   // text-align: right;
// }
.swiper1 .my-pagination-clickable {
  // position: absolute;
  width: auto;
  bottom: 40px;
  right: 72px;
  text-align: right;
}

// .swiper-pagination{
//         // width: 120px;
//         // position: absolute !important;
//         width: auto;
//         bottom: 40px;
//         right: 72px !important;
//         text-align: right;
// }
// .swiper-pagination-bullets{
//   left: auto;
// }
/* 修改分页器圆圈的颜色 */
::v-deep .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.64);
  width: 12px;
  height: 12px;
}

/* 修改活动状态的分页器圆圈颜色 */
::v-deep .swiper-pagination-bullet-active {
  background-color: rgba(255, 255, 255, 0.64);
  width: 30px;
  height: 12px;
  border-radius: 38px;
}

::v-deep .swiper-container {
  overflow: hidden !important; /* 隐藏溢出内容 */
}

/* 如果需要隐藏垂直滚动条，添加以下样式 */
::v-deep .swiper-scrollbar {
  display: none !important;
}
// .swiper-pagination {
//   // 自定义分页器样式
//      ::v-deep .swiper-pagination-bullet {
//       width: 20px;
//       height: 20px;
//       text-align: center;
//       line-height: 20px;
//       font-size: 12px;
//       color: #000;
//       opacity: 1;
//       background: rgba(0,0,0,0.2);
//     }
//     ::v-deep .swiper-pagination-bullet-active {
//       color: #fff;
//       background: #007aff;
//     }
//   }
// .swiper-container {
//   width: 80%;
//   margin: auto;
// }

// .swiper-slide {
//   width: 80%; /* 设置每张轮播项宽度为80% */
//   box-sizing: border-box;
// }
</style>
