<template>
    <div>
      <!-- 根据计算属性的值显示不同的组件 -->
      <component :is="currentComponent"></component>
    </div>
  </template>
  
  <script>
  import ComponentMB from '../contactMB/index.vue'; // 导入第一个组件
  import ComponentPC from '../contactPC/index.vue'; // 导入第二个组件
  
  export default {
    components: {
      ComponentMB,
      ComponentPC,
    },
    computed: {
      currentComponent() {
        // 根据窗口宽度判断应该显示哪个组件
        return window.innerWidth <= 750 ? 'ComponentMB' : 'ComponentPC';
      },
    },
  };
  </script>